<template>
  <main>
    <div class="layout-subcontainer">
      <div class="box-wrap d-flex">
        <div class="col-6 pa-0">
          <h1 class="tit-h1 d-flex">이력고객관리
            <div class="ml-auto">
              <v-btn outlined small class="btn-etc2 ml-2"  @click="getListData" id="btnSearch">조회</v-btn>
            </div>
          </h1>
          <div class="box-ct mt-2">
            <div class="table-form d-flex">
              <table>
                <caption class="hide">
                  이력고객목록
                </caption>
                <colgroup>
                  <col width="148px" />
                  <col width="240px" />
                  <col width="148px" />
                  <col width="" />
                </colgroup>
                <tbody>
                  <tr>
                    <th scope="row" >
                      이름
                    </th>
                    <td >
                      <div class="form-inp full" >
                        <v-text-field
                        class="form-inp"
                        outlined
                        hide-details
                        v-model="S_USER_NAME"
                        v-on:keyup.enter="getListData"
                        >
                        </v-text-field>
                      </div>
                    </td>
                    <th scope="row" >
                      전화번호
                    </th>
                    <td >
                      <div class="form-inp full" >
                        <v-text-field
                        class="form-inp"
                        oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
                        maxlength="11"
                        outlined
                        hide-details
                        v-model="S_USER_PHONE"
                        v-on:keyup.enter="getListData"
                        >
                        </v-text-field>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="box-ct mt-3">
            <h1 class="tit-h1 d-flex">이력고객 목록
              <div class="ml-auto">
                <v-btn
                  outlined
                  class="btn-default ml-2"
                  id="btnExcelUpload" 
                  type="button"
                  @click="excelUpload"
                >엑셀업로드</v-btn>
                <v-file-input
                  ref="excelRef"
                  v-model="fileValue"
                  v-show="false"
                  @change="excelUploadRun"
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  counter
                  label="엑셀파일을 선택하세요"
                  :show-size="1000"
                />

              <v-btn
                outlined
                class="btn-default ml-2"
                @click="downloadExcel('이력고객 목록 현황')"
              >엑셀다운로드</v-btn>
              <v-btn
                  outlined
                  class="btn-etc ml-2"
                  @click="newBtnForm('NEW')"
              >신규</v-btn
              >
              <v-btn
                  outlined
                  class="btn-point ml-2"
                  @click="newBtnForm('UPDATE')"
              >수정</v-btn
              >
              </div>
              </h1>
              <v-dialog v-if="dialogCUP0100" max-width="655" persistent v-model="dialogCUP0100"
                content-class="square-modal min-auto">
                <dialog-CUP0100
                  :param="CUP0100Param"
                  p01
                  @hide="hideDialog('CUP0100')"
                  @hideAndGetList="hideAndGetList('CUP0100')"
                >
                </dialog-CUP0100>
              </v-dialog>
            <div class="mt-2">
              <v-data-table
                dense
                height="250px"
                :headers="gridDataHeadersInfo"
                :items="gridDataText"
                :items-per-page="itemsPerPage"
                item-key="IDX"
                :page.sync="page"
                hide-default-footer
                class="grid-default"
                fixed-header
                id="mainTable"
                @page-count="pageCount = $event"
                @click:row="gridRowClick"
                v-model="selGroupCd"
                :item-class="clickActive"
                no-data-text="검색된 결과가 없습니다."
              ></v-data-table>
              <div class="grid-paging text-center pt-2">
                <span class="grid-total-count">총 <strong>{{ gridDataText.length }}</strong>건</span>
                <v-pagination
                    v-model="page"
                    :length="pageCount"
                    :total-visible="totalVisible"
                    next-icon="svg-paging-next"
                    prev-icon="svg-paging-prev"
                ></v-pagination>
              </div>
            </div>
          </div>
          <h1 class="tit-h1 d-flex">
              이력고객 상세목록
              <div class="ml-auto">
              <v-btn
                outlined
                class="btn-default ml-2"
                @click="downloadExcelDetail('이력고객 상세목록 조회')"
              >엑셀다운로드</v-btn>
              </div>
            </h1>
  
          <div class="mt-2">
            <v-data-table
              dense
              height="250px"
              :headers="gridDataHeadersDetail"
              :items="gridDataTextDetail"
              :items-per-page="itemsDetailPerPage"
              item-key="IDX"
              :page.sync="page2"
              hide-default-footer
              class="grid-default"
              fixed-header
              id="mainTable"
              @page-count="pageDetailCount = $event"
              @click:row="gridDetailRowClick"
              v-model="selDetailGroupCd"
              :item-class="clickActiveDetail"
              no-data-text="검색된 결과가 없습니다."
            ></v-data-table>
              <div class="grid-paging text-center pt-2">
                <span class="grid-total-count">총 <strong>{{ gridDataTextDetail.length }}</strong>건</span>
                <v-pagination
                    v-model="page2"
                    :length="pageDetailCount"
                    :total-visible="totalDetailVisible"
                    next-icon="svg-paging-next"
                    prev-icon="svg-paging-prev"
                ></v-pagination>
              </div>
            </div>
        </div>
        <!-- 알림톡 상세내용 -->
        <div class="ml-6 flex-grow-1">
          <h1 class="tit-h1 d-flex">이력고객 상세내용
            <div class="ml-auto">
              <v-btn
                outlined
                class="btn-default ml-2"
                @click="downloadExcelDetailInfo('이력고객 상세내용 조회')"
              >엑셀다운로드</v-btn>
              </div>
          </h1>
          <div class="box-ct mt-2">
            <div class="table-form d-flex">
              <table>
                <caption class="hide">
                  파트너 정보 항목
                </caption>
                <colgroup>
                  <col width="30%" />
                  <col width="30%" />
                  <col width="30%" />
                  <col width="30%" />
                  <col width="30%" />
                </colgroup>
                <tbody>
                  <tr>
                    <th scope="row">건설사명
                    </th>
                    <td>
                      <v-textarea
                        width:500px
                        class="form-inp full"
                        counter
                        no-resize
                        outlined
                        readonly
                        v-model="T_CTCP_NAME"
                      >
                      </v-textarea>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">사업지명
                    </th>
                    <td>
                      <v-textarea
                        width:500px
                        class="form-inp full"
                        counter
                        no-resize
                        outlined
                        readonly
                        v-model="T_BUSS_NAME"
                      >
                      </v-textarea>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">캠페인명
                    </th>
                    <td>
                      <v-textarea
                        width:500px
                        class="form-inp full"
                        counter
                        no-resize
                        outlined
                        readonly
                        v-model="T_CMP_NAME"
                      >
                      </v-textarea>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">고객명
                    </th>
                    <td style="width:100%">
                      <div class="form-inp full">
                        <v-textarea
                        width:500px
                        class="form-inp full"
                        counter
                        no-resize
                        outlined
                        readonly
                        v-model="T_USER_NAME"
                        ></v-textarea>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">전화번호
                    </th>
                    <td>
                      <div class="form-inp full">
                        <v-textarea
                        class="form-inp full"
                        counter
                        no-resize
                        outlined
                        readonly
                        v-model="T_USER_PHONE"
                        ></v-textarea>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">등록일시
                    </th>
                    <td>
                      <div class="form-inp full">
                        <v-textarea
                        class="form-inp full"
                        counter
                        no-resize
                        outlined
                        readonly
                        v-model="T_REG_DT"
                        ></v-textarea>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">예약일시
                    </th>
                    <td>
                      <div class="form-inp full">
                        <v-textarea
                        class="form-inp full"
                        counter
                        no-resize
                        outlined
                        readonly
                        v-model="T_RSVT_DT"
                        ></v-textarea>
                      </div>
                    </td>
                  </tr>
                  
                  <tr>
                    <th scope="row">개인정보수집동의</th>
                    <td>
                      <v-textarea
                        class="form-inp full"
                        counter
                        no-resize
                        outlined
                        readonly
                        v-model="T_PRSN_AGRE_YN"
                        ></v-textarea>
                    </td> 
                  </tr>
                  <tr>
                    <th scope="row" >마케팅활용동의</th>
                    <td>
                      <v-textarea
                        class="form-inp full"
                        counter
                        no-resize
                        outlined
                        readonly
                        v-model="T_MKTG_AGRE_YN"
                        ></v-textarea>
                    </td> 
                  </tr>
                  <tr>
                    <th scope="row" >방문등록타입</th>
                    <td>
                      <v-textarea
                        class="form-inp full"
                        counter
                        no-resize
                        outlined
                        readonly
                        v-model="T_VIST_GUB_NAME"
           
                      >
                      </v-textarea>
                    </td> 
                  </tr>
                  <tr>
                    <th scope="row" >고객경로</th>
                    <td>
                      <v-textarea
                        class="form-inp full"
                        counter
                        no-resize
                        outlined
                        readonly
                        v-model="T_TRGT_GUB_NAME"
           
                      >
                      </v-textarea>
                    </td> 
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
  import DialogCUP0100 from "./CUP0100.vue";
  import XLSX from "xlsx"

  export default {
    name: "MENU_CUM0300", //name은 'MENU_' + 파일명 조합
    components: {
      DialogCUP0100,
    },
    data() {
      return {
        menu1:false,
        fileValue: null,
        dialogCUP0100: false,
        CUP0100Param:{},
        bussTypeCd:[],
        ctcpTypeCd:[],
        cmpTypeCd:[],

        CmdCdT:[],
        selGroupCd:[],
        selDetailGroupCd:[],
        gridDataText: [],
        gridDataTextDetail:[],
        gridDataTextInfo:[],
        
        BUSS_CODE: "",
        S_USER_NAME: "",
        S_USER_PHONE: "",

        ROW_NUMBER:"",
        T_CTCP_NAME: "",
        T_BUSS_NAME: "",
        T_CMP_NAME: "",
        T_USER_NAME: "",
        T_USER_PHONE: "",
        T_REG_DT: "",
        T_RSVT_DT: "",
        T_PRSN_AGRE_YN: "",
        T_MKTG_AGRE_YN: "",
        T_VIST_GUB_NAME: "",
        T_TRGT_GUB_NAME: "",
        USER_AUTH_CHK : "",
        allCodeList: "",
        EXCEL_CUST_PHONE:"",
        D_EXCEL_CUST_NAME:"",
        ITEM_CUST_PHONE:"",
        
        gridDataHeadersInfo: [
          { text: "No",              value: "IDX",          align: "center", width: "50px", sortable: true  },
          { text: "고객명",           value: "CUST_NAME",           align: "center", width: "100px", sortable: true },
          { text: "전화번호",         value: "CUST_PHONE",          align: "center", width: "100px", sortable: true },
        ],
        page: 1,
        page2: 1,
        pageCount: 0,
        itemsPerPage: 30,
        totalVisible: 10,

        gridDataHeadersDetail: [
          { text: "No",              value: "IDX",          align: "center", width: "50px", sortable: true  },
          { text: "캠페인명",        value: "CMP_NAME",          align: "center", width: "160px", sortable: true },
          { text: "고객명",           value: "CUST_NAME",         align: "center", width: "100px", sortable: true },
          { text: "전화번호",         value: "CUST_PHONE", align: "center", width: "100px", sortable: true },
          { text: "방문등록타입",      value: "VIST_GUB_NAME",        align: "center", width: "140px", sortable: true },
          { text: "개인정보수집동의",     value: "PRSN_AGRE_YN",    align: "center", width: "140px", sortable: true },
          { text: "마케팅활용동의",     value: "MKTG_AGRE_YN",    align: "center", width: "140px", sortable: true },

          { text: "건설사명",         value: "CTCP_NAME",  width: '0px' ,  align: 'center', sortable: false ,  "align": " d-none" , show:false },
          { text: "사업지명",         value: "BUSS_NAME",  width: '0px' ,  align: 'center', sortable: false ,  "align": " d-none" , show:false },
          { text: "등록일시",           value: "REG_DT",  width: '0px' ,  align: 'center', sortable: false ,  "align": " d-none" , show:false },
          { text: "예약일시",           value: "CNSL_RSVT_DT",  width: '0px' ,  align: 'center', sortable: false ,  "align": " d-none" , show:false },
          { text: "개인정보수집동의",     value: "PRSN_AGRE_YN",  width: '0px' ,  align: 'center', sortable: false ,  "align": " d-none" , show:false },
          { text: "마케팅활용동의",     value: "MKTG_AGRE_YN",  width: '0px' ,  align: 'center', sortable: false ,  "align": " d-none" , show:false },
          { text: "고객경로",          value: "TRGT_GUB_NAME",      width: '0px' ,  align: 'center', sortable: false ,  "align": " d-none" , show:false },
        ],
        
        pageDetailCount: 0,
        itemsDetailPerPage: 30,
        totalDetailVisible: 10,
        
        excelDataHeaders: [
          { text: "고객명",         value: "CUST_NAME",       align: "center", width: "200px",  sortable: true},
          { text: "전화번호",         value: "CUST_PHONE",       align: "center", width: "300px",  sortable: true},
        ],

        excelDataDetailHeaders: [
          { text: "건설사명",         value: "CTCP_NAME",       align: "center", width: "200px"},
          { text: "사업지명",         value: "BUSS_NAME",       align: "center", width: "300px"},
          { text: "캠페인명",        value: "CMP_NAME",          align: "center", width: "300px" },
          { text: "고객명",           value: "CUST_NAME",         align: "center", width: "120px" },
          { text: "전화번호",         value: "CUST_PHONE",        align: "center", width: "280px"},
          { text: "등록일시",           value: "REG_DT",           align: "center", width: "400px" },
          { text: "예약일시",           value: "CNSL_RSVT_DT",     align: "center", width: "400px" },
          { text: "개인정보수집동의",     value: "PRSN_AGRE_YN",    align: "center", width: "120px" },
          { text: "마케팅활용동의",     value: "MKTG_AGRE_YN",      align: "center", width: "120px"},
          { text: "방문등록타입",      value: "VIST_GUB_NAME",        align: "center", width: "100px"},
          { text: "고객경로",          value: "TRGT_GUB_NAME",        align: "center", width: "100px" },

        ],


      };
    },

    methods: {

      startDate(e) {
        this.dates[0] = e;
      },

      endDate(e) {
        this.dates[1] = e;
      },

      activeRow(row, value) {
        this.selectedRow = row;
        row.select(Boolean(value));
        row.isSelected = Boolean(value);
      },
      
      disableRow(row, value) {
        row.select(Boolean(value));
        row.isSelected = Boolean(value);
      },

      activeRowTop(row, value) {
        this.selectedRow = row;
        row.select(Boolean(value));
        row.isSelected = Boolean(value);
      },
      
      disableRowTop(row, value) {
        row.select(Boolean(value));
        row.isSelected = Boolean(value);
      },

      cleanHeaders(){
        this.gridDataHeadersDetail= [
          { text: "No",              value: "IDX",          align: "center", width: "50px", sortable: true  },
          { text: "캠페인명",        value: "CMP_NAME",          align: "center", width: "160px", sortable: true },
          { text: "고객명",           value: "CUST_NAME",         align: "center", width: "100px", sortable: true },
          { text: "전화번호",         value: "CUST_PHONE", align: "center", width: "100px", sortable: true },
          { text: "방문등록타입",      value: "VIST_GUB_NAME",        align: "center", width: "80px", sortable: true },
          { text: "개인정보수집동의",     value: "PRSN_AGRE_YN",    align: "center", width: "80px", sortable: true },
          { text: "마케팅활용동의",     value: "MKTG_AGRE_YN",    align: "center", width: "80px", sortable: true },

          { text: "건설사명",         value: "CTCP_NAME",  width: '0px' ,  align: 'center', sortable: false ,  "align": " d-none" , show:false },
          { text: "사업지명",         value: "BUSS_NAME",  width: '0px' ,  align: 'center', sortable: false ,  "align": " d-none" , show:false },
          { text: "등록일시",           value: "REG_DT",  width: '0px' ,  align: 'center', sortable: false ,  "align": " d-none" , show:false },
          { text: "예약일시",           value: "CNSL_RSVT_DT",  width: '0px' ,  align: 'center', sortable: false ,  "align": " d-none" , show:false },
          { text: "개인정보수집동의",     value: "PRSN_AGRE_YN",  width: '0px' ,  align: 'center', sortable: false ,  "align": " d-none" , show:false },
          { text: "마케팅활용동의",     value: "MKTG_AGRE_YN",  width: '0px' ,  align: 'center', sortable: false ,  "align": " d-none" , show:false },
          { text: "고객경로",          value: "TRGT_GUB_NAME",      width: '0px' ,  align: 'center', sortable: false ,  "align": " d-none" , show:false },
        ];
      },

      //**********************************
      //   고객 목록 조회 호출
      //**********************************
      async getListData() {

        if( this.mixin_isEmpty(this.S_USER_PHONE) && this.mixin_isEmpty(this.S_USER_NAME) ) {
          this.common_alert('조회조건은 필수입니다.', 'chk');
          return false;
        }

        let requestData = {
          headers: {},
          sendData:{},
        };

        //header 세팅
        requestData.headers["URL"] = "/api/hlw/campaign/cust-custHist/manage/list";
        requestData.headers["SERVICE"] = "hlw.campaign.cust-custHist.manage";
        requestData.headers["METHOD"] = "list";
        requestData.headers["ASYNC"] = false;

        //sendData 초기화
        requestData.sendData["CUST_PHONE"] = this.S_USER_PHONE;     //휴대전화
        requestData.sendData["CUST_NAME"] = this.S_USER_NAME;      //이름
        
        let response = await this.common_postCall(requestData);

        if(!response.HEADER.ERROR_FLAG) {
          this.cleanHeaders();
          this.result(response);
          this.cleanValidation();   //오른쪽 내용 초기화
          this.gridDataTextDetail=[];   //아래 내용 초기화
        }else{
          this.common_alert("관리자에게 문의해주세요.", "error");
        }
      },

      //**********************************
      //   고객 목록 조회 호출
      //**********************************
      async getListDetailData() {

        let requestDetailData = {
          headers: {},
          sendData:{},
        };

        //header 세팅
        requestDetailData.headers["URL"] = "/api/hlw/campaign/cust-custDetailHist/manage/list";
        requestDetailData.headers["SERVICE"] = "hlw.campaign.cust-custDetailHist.manage";
        requestDetailData.headers["METHOD"] = "list";
        requestDetailData.headers["ASYNC"] = false;

        //sendData 초기화
        requestDetailData.sendData["CUST_PHONE"] = this.ITEM_CUST_PHONE.replace(/[^0-9]/g, '');     //휴대전화

        let responseDetail = await this.common_postCall(requestDetailData);

        if(!responseDetail.HEADER.ERROR_FLAG) {
          this.resultDetail(responseDetail);
          this.cleanValidation();   //오른쪽 내용 초기화
        }else{
          this.common_alert("관리자에게 문의해주세요.", "error");
        }
      },

      //엑셀다운로드(목록)
      downloadExcel(fileNm) {
        //엑셀다운로드
        let fileName = fileNm;
        this.mixin_common_Excel(this.excelDataHeaders, this.gridDataText, fileName, this.EXCEL_CUST_PHONE);
      },

      //엑셀다운로드(상세목록)
      downloadExcelDetail(fileNm) {
        let fileName =  this.D_EXCEL_CUST_NAME + '_' + fileNm;
        this.mixin_common_Excel(this.excelDataDetailHeaders, this.gridDataTextDetail, fileName, this.D_EXCEL_CUST_NAME);
      },

      //엑셀다운로드(상세내용)
      downloadExcelDetailInfo(fileNm) {
        if(!this.mixin_isEmpty(this.gridDataTextInfo)) {
          let jsonObj = JSON.parse(JSON.stringify(this.gridDataTextInfo)); //json string data를 object 형태로 파싱
          this.gridDataTextInfo = ([jsonObj]) ;

          let fileName =  this.T_USER_NAME + '_' + fileNm;

          this.mixin_common_Excel(this.excelDataDetailHeaders, this.gridDataTextInfo, fileName, this.D_EXCEL_CUST_NAME);
          this.gridDataTextInfo = [];
        } else {
          this.common_alert('목록 데이터를 클릭후 다운로드 받아주세요.', 'chk');
          return;
        }
      },

      //엑셀내용확인
      validate (dataForm) {
        dataForm.forEach((j) => {
          if(j['CUST_NAME'] === ""){
            const msg = '고객이름 필수입니다.';
            this.common_alert(msg, 'chk');
            return false;
          }
          
          if(j['CUST_PHONE'] === ""){
            const msg = '전화번호는 필수입니다.';
            this.common_alert(msg, 'chk');
            return false;
          }
        });
        return true;
      },

      //엑셀 업로드 저장확인
      processValidate(dataForm, custlength) {
        this.common_confirm("저장하시겠습니까?<br>총"+custlength+"건", this.submit, dataForm, null, null, "chk");
      },

      //엑셀 업로드
      async submit(dataForm) {
        if(!this.validate(dataForm)) {
          return;
        }

        let requestDataExcel = {
          headers: {},
          sendData: {},
        };
      
        requestDataExcel.headers["URL"] = "/api/hlw/campaign/cust-custInfo-multi/manage/regist";
        requestDataExcel.headers["SERVICE"] = "hlw.campaign.cust-custInfo-multi.manage";
        requestDataExcel.headers["METHOD"] = "regist";
        requestDataExcel.headers["ASYNC"] = false;

        requestDataExcel.sendData["BUSS_CODE"]   = "CUM0300";       //엑셀업로드, 수동 입력 했을때 코드
        requestDataExcel.sendData["REGR_ID"]     = this.user_id;

        if(dataForm) {
          requestDataExcel.headers['DATA_OBJECT'] = 'CUST_LIST'
          requestDataExcel.sendData['CUST_LIST'] = dataForm
        }

        const responseExcel = await this.common_postCall(requestDataExcel);

        if (!responseExcel.HEADER.ERROR_FLAG) {
          this.common_alert("업로드가 완료 되었습니다.<br>"+responseExcel.HEADER.TOT_COUNT+"건", "chk");
          this.getListData();
        }else{
          this.common_alert("관리자에게 문의해주세요.", "error");
        }

      },
      
      //이력고객 목록 클릭시
      gridRowClick(item, row) {
        
        this.cleanHeaders();// 헤더 초기화

        if (!this.mixin_isEmpty(item)) {
          if (row.isSelected == false) {
            this.activeRowTop(row, true);
          } else {
            this.disableRowTop(row, false);
          }
        }
        
        this.ROW_NUMBER            = item.IDX;
        this.ITEM_CUST_PHONE       = item.CUST_PHONE;
        this.ITEM_CUST_NAME       = item.CUST_NAME;

        this.getListDetailData();

      },

      gridDetailRowClick(item, row) {
        this.gridDataTextInfo = "";
        if (item != null) {
          if (row.isSelected == false) {
            this.activeRow(row, true);
          } else {
            this.disableRow(row, false);
          }
        }
        this.ROW_NUMBER       = item.IDX;
        this.T_CTCP_NAME      = item.CTCP_NAME;
        this.T_BUSS_NAME      = item.BUSS_NAME;
        this.T_CMP_NAME       = item.CMP_NAME;
        this.T_USER_NAME      = item.CUST_NAME;
        this.T_USER_PHONE     = item.CUST_PHONE;
        this.T_REG_DT         = item.REG_DT;
        this.T_RSVT_DT        = item.CNSL_RSVT_DT;
        this.T_PRSN_AGRE_YN   = item.PRSN_AGRE_YN;
        this.T_MKTG_AGRE_YN   = item.MKTG_AGRE_YN;
        this.T_VIST_GUB_NAME  = item.VIST_GUB_NAME;
        this.T_TRGT_GUB_NAME  = item.TRGT_GUB_NAME;
        this.gridDataTextInfo = item;

      },

      cleanValidation() {
        this.T_CTCP_NAME      = "";
        this.T_BUSS_NAME      = "";
        this.T_CMP_NAME       = "";
        this.T_USER_NAME      = "";
        this.T_USER_PHONE     = "";
        this.T_REG_DT         = "";
        this.T_RSVT_DT        = "";
        this.T_PRSN_AGRE_YN   = "";
        this.T_MKTG_AGRE_YN   = "";
        this.T_VIST_GUB_NAME  = "";
        this.T_TRGT_GUB_NAME  = "";
      },

      clickActiveDetail(item){
        //클릭 시, 하이라이트
        return item.IDX == this.ROW_NUMBER ? 'active' : '';
      },

      clickActive(item){
        //클릭 시, 하이라이트
        return item.IDX == this.ROW_NUMBER ? 'active' : '';
      },

      result(res){
        let header = res.HEADER;
        this.totcount = header.TOT_COUNT;

        let data = res.DATA;
        this.gridDataText = data;
        this.EXCEL_CUST_PHONE= ""//이전내용 초기화
        if (this.gridDataText.length === 0) {
          return;
        }
        this.EXCEL_CUST_PHONE= this.gridDataText[0]["CUST_PHONE"];

        let idx = 1;
        for(var i in this.gridDataText){
          this.gridDataText[i]["IDX"] = idx++;
        }
      },

      resultDetail(res){
        let header = res.HEADER;
        this.totcount = header.TOT_COUNT;

        let data = res.DATA;
        this.gridDataTextDetail = data;
        this.D_EXCEL_CUST_NAME= ""//이전내용 초기화
        if (this.gridDataTextDetail.length === 0) {
          return;
        }
        this.D_EXCEL_CUST_NAME= this.gridDataTextDetail[0]["CUST_NAME"];
    
        let idx = 1;
        let cnt = 0;
        for(var i in this.gridDataTextDetail){
          this.gridDataTextDetail[i]["IDX"] = idx++;
          if(res.DATA[i]["QUTN_ITEM_NAME"] !== ''){

            if ( cnt === 0 ){
              for(var d in res.DATA[i]["QUTN_ITEM_NAME"]){              
                this.gridDataHeadersDetail.push({ text: "질문", value:"QUTN_ITEM_NAME" + d,  align: "center", width: "120px", sortable: true },)
                this.gridDataHeadersDetail.push({ text: "답", value: "QUTN_ITEM_DETL_CODE" + d,  align: "center", width: "120px", sortable: true },)  
                this.excelDataHeaders.push({ text: "질문"+ d, value:"QUTN_ITEM_NAME" + d,  align: "center", width: "120px", sortable: true },)
                this.excelDataHeaders.push({ text: "답"+ d, value: "QUTN_ITEM_DETL_CODE" + d,  align: "center", width: "120px", sortable: true },)  
                
                this.gridDataTextDetail[i]["QUTN_ITEM_NAME"+[d]] = res.DATA[i]["QUTN_ITEM_NAME"][d];
                this.gridDataTextDetail[i]["QUTN_ITEM_DETL_CODE"+[d]] = res.DATA[i]["QUTN_ITEM_DETL_CODE"][d];

                cnt = cnt + 1;  
              } 

            }else{
              for(var c in res.DATA[i]["QUTN_ITEM_NAME"]){
                if ( c >= cnt  ){
                  
                  //0 1  1 1 
                  this.gridDataHeadersDetail.push({ text: "질문", value:"QUTN_ITEM_NAME" + c,  align: "center", width: "120px", sortable: true },)
                  this.gridDataHeadersDetail.push({ text: "답", value: "QUTN_ITEM_DETL_CODE" + c,  align: "center", width: "120px", sortable: true },)  
                  this.excelDataHeaders.push({ text: "질문"+ c, value:"QUTN_ITEM_NAME" + c,  align: "center", width: "120px", sortable: true },)
                  this.excelDataHeaders.push({ text: "답"+ c, value: "QUTN_ITEM_DETL_CODE" + c,  align: "center", width: "120px", sortable: true },)  
                  this.gridDataTextDetail[i]["QUTN_ITEM_NAME"+[c]] = res.DATA[i]["QUTN_ITEM_NAME"][c];
                  this.gridDataTextDetail[i]["QUTN_ITEM_DETL_CODE"+[c]] = res.DATA[i]["QUTN_ITEM_DETL_CODE"][c];
                  cnt = cnt + 1 ;
                }else{
                  this.gridDataTextDetail[i]["QUTN_ITEM_NAME"+[c]] = res.DATA[i]["QUTN_ITEM_NAME"][c];
                  this.gridDataTextDetail[i]["QUTN_ITEM_DETL_CODE"+[c]] = res.DATA[i]["QUTN_ITEM_DETL_CODE"][c];

                }
                if (this.gridDataTextDetail[i]["QUTN_ITEM_DETL_CODE"+[c]].includes('http://admin.opentohome.com:8443/api/file/hlw/images/') ){
                  this.gridDataTextDetail[i]["IMG_QUTN_RPLY"] = res.DATA[i]["QUTN_ITEM_DETL_CODE"][c];
                } 
              }
            }
          }
        }
      },

      showDialog(type) {
        this[`dialog${type}`] = true;
      },

      hideDialog(type) {
        this[`dialog${type}`] = false;
      },

      submitDialog(type) {
        this.gridDataText = [];
        this.selGroupCd = [];
        this.btnSearch();
        this[`dialog${type}`] = false;
      },

      resetDialog(type) {
        this.gridDataText = [];
        this.selGroupCd = [];
        this.btnSearch();
        this[`dialog${type}`] = false;
      },

      unlockDialog(type) {
        this.gridDataText = [];
        this.selGroupCd = [];
        this.btnSearch();
        this[`dialog${type}`] = false;
      },

      excelUpload() {
        this.$refs.excelRef.$refs.input.click();
      },

      excelUploadRun(file) {
        const fileName = file.name;
        const reader = new FileReader();
        const gridCustItems = [];
        reader.onload = (e) => {
          var buffer = reader.result
          var data = new Uint8Array(buffer);
          var arr = new Array()
          for (var i = 0; i != data.length; ++i)
            arr[i] = String.fromCharCode(data[i]);
          var bstr = arr.join("");
          var workbook = XLSX.read(bstr, {
            type: "binary",
            cellDates: true,
          });
          var first_sheet_name = workbook.SheetNames[0];
          var worksheet = workbook.Sheets[first_sheet_name];
          var getData = XLSX.utils.sheet_to_json(worksheet, { header:2, raw:false, blankRows:false, defval:'' });
      
          if(!this.mixin_isEmpty(getData)){
            for(let i = 0; i < getData.length; i++){
              let dataForm = {
                "CUST_NAME" : getData[i]["고객명 "],
                "CUST_PHONE" : this.mixin_dateFormat(getData[i]["전화번호 "])
              };
              gridCustItems.push(dataForm);
            }
            this.processValidate(gridCustItems, getData.length);
          }
        }

        reader.readAsArrayBuffer(file);
      },

      // 버튼목록:신규버튼
      newBtnForm(code){
        if (code ==="UPDATE") {
          if (this.ITEM_CUST_PHONE === ""){
            this.common_alert("고객정보를 선택해주세요.", "chk");
            return;
          }
        }

        this.CUP0100Param = {
          headerTitle: "고객등록",
          custPhone : this.ITEM_CUST_PHONE,
          custName : this.ITEM_CUST_NAME,
          type: code,
        };
        this.showDialog('CUP0100');
      },
    
    },
    
    mounted() {
    },

    computed: {
      initHeaders(){
        return {
        };
      },
      paramData(){
        return {
        };
      }
    },
  };
</script>